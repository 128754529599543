import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import styles from './css/inboxStyle.module.css'
// import './css/inbox-resp.css'

import { db } from './firebaseSetup';
import { collection, doc, getDocs, getDoc, where, query, addDoc, orderBy, updateDoc, onSnapshot, arrayUnion } from 'firebase/firestore';
import { storage } from './firebaseSetup';
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage'
import { getAuth } from "firebase/auth";
import { url } from '../constData'
import blackImage from './images/blackImage.png'
import CloseIcon from '@mui/icons-material/Close';
import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import attach from './images/icons/attach.svg'
import { toast } from 'react-toastify';
import { getChatName } from '../utils/getChatName';
import { getChatProfile } from '../utils/getChatProfile';
import { getMyProfileImage } from '../utils/getMyProfileImage';
import { getReceiverId } from '../utils/getReceiverId';
import { sendInAppNotification } from '../utils/sendInAppNotification';
import { sendPushNotification } from '../utils/sendPushNotification';
import { getServerAccessToken } from '../utils/getServerAccessToken';

function inboxPage() {

    // const location = useLocation();
    // if(location.state !== undefined && location.state !== null) {
    // const locationStateGroupIndex = location.state.groupIndex;
    // const locationStateGroupId = location.state.groupId;
    // }

    const auth = getAuth();


    const [fuser, setFuser] = useState(null);
    const [message, setMessage] = useState([]);
    let msgArray = [];
    const [inputValue, setInputValue] = useState('')
    const [chatUser, setChatUser] = useState(null);
    let allUserProfilePic = [];
    const [allGroupData, setAllGroupData] = useState([]);
    const [newUserDetails, setNewUserDetails] = useState([]);
    let aGroupData = [];
    let nUserDetails = [];

    const navigate = useNavigate();

    const inputFile = useRef(null);

    const messagesEndRef = useRef(null);

    const userCollectionRef = collection(db, "users",)
    const groupCollectionRef = collection(db, "chats")

    const [open, setOpen] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [sendImg, setSendImg] = useState(null);
    const [reloadtime, setReloadTime] = useState(0);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [userGroup, setUserGroup] = useState(null);
    const [viewImage, setViewImage] = useState(null);

    const [loggedUser, setLoggedUser] = useState(null);
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [receiverProfileImage, setReceiverProfileImage] = useState(null);

    const [chats, setChats] = useState(null);
    const [chatName, setChatName] = useState(null);
    const [selectedChatId, setSelectedChatId] = useState(null); // Selected chat ID
    const [messages, setMessages] = useState([]); // Messages for the selected chat
    const [serverAccessToken, setServerAccessToken] = useState(null); // server access token for firebase push notifications



    // Function to get an access token
    const getAccessToken = async () => {
        const data = await getServerAccessToken();
        setServerAccessToken(data);
    };

    useEffect(() => {
        getAccessToken();
        if (typeof window !== "undefined") {
            setOpen(true);
            // Reference to the "chats" collection
            const userData = JSON.parse(localStorage.getItem("userData"));
            setLoggedUser(userData);
            const chatsCollection = collection(db, "chats");

            const memberKey = `${userData._id}__${userData.fname.split(" ").join("_")}`;

            const groupsQuery = query(
                chatsCollection,
                where("members", "array-contains", memberKey)
            );

            // Subscribe to the collection
            const unsubscribe = onSnapshot(groupsQuery, (snapshot) => {
                if (snapshot.docs.length > 0) {
                    setUserProfileImage(getMyProfileImage(snapshot.docs[0].data().image, userData._id));
                }
                const chatData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: getChatName(doc.data()['members'], userData._id),
                    profileImage: getChatProfile(doc.data()['image'], userData._id),
                    ...doc.data(),
                }));
                setChats(chatData);
                setOpen(false);
            });

            // Cleanup the subscription on component unmount
            return () => unsubscribe();
        }
    }, []);


    // Listen for messages in the selected chat
    useEffect(() => {
        if (selectedChatId) {
            const messagesCollection = collection(db, `chats/${selectedChatId}/messages`);
            const messagesQuery = query(messagesCollection, orderBy("time", "asc"));

            const unsubscribeMessages = onSnapshot(messagesQuery, (snapshot) => {
                const messageData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(messageData);
            });

            return () => unsubscribeMessages(); // Cleanup messages listener
        } else {
            setMessages([]); // Clear messages if no chat is selected
        }
    }, [selectedChatId]);

    // Scroll to the bottom of the messages
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    // Scroll to the bottom when messages are updated
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const selectChat = (chatId, image, chatName) => {
        setReceiverProfileImage(image);
        setChatName(chatName);
        setSelectedChatId(chatId);
    }

    // Send Message Function
    const saveMessage = async () => {
        try {
            let messageContent = inputValue.trim(); // For text, use content directly
            let type = "text";
            const currentTime = Date.now(); // Get the current system time


            if ((sendImg && inputValue.trim().length === 0) || (sendImg && inputValue.trim().length > 0)) {
                type = "img";
                // Upload the image if the type is 'img'
                const date = new Date().toISOString().split("T")[0];
                const storageRef = ref(storage, `images/${selectedChatId}/${selectedChatId}_${date}_${sendImg.name}`);
                const snapshot = await uploadBytes(storageRef, sendImg);
                messageContent = await getDownloadURL(snapshot.ref); // Get the image URL
            } else if (sendImg === null && inputValue.trim().length > 0) {
                type = "text";
                messageContent = inputValue.trim();
            }

            // Add the message to the Firestore sub-collection
            const messagesRef = collection(db, `chats/${selectedChatId}/messages`);

            // Message object to save
            const newMessage = {
                message: messageContent, // Message content (text or other type)
                sender: loggedUser.fname,  // Sender's ID or name
                time: currentTime,
                type: type,
            };

            // Add the message to Firestore
            await addDoc(messagesRef, newMessage);

            // Reference to the group document
            const groupDocRef = doc(db, "chats", selectedChatId);

            // Update recent message details in the group document
            await updateDoc(groupDocRef, {
                recentMessage: type === "img" ? "img" : newMessage.message,
                recentMessageSender: newMessage.sender,
                recentMessageTime: `${newMessage.time}`,
                recentMessageType: newMessage.type,
            });
            sendInAppNotification(loggedUser._id, newMessage.message, userProfileImage);
            sendPushNotification(newMessage.sender, getReceiverId(selectedChatId, loggedUser._id), newMessage.type === "img" ? 'Sent an Image' : newMessage.message, serverAccessToken, "chat");

            // Clear the Message Input
            setInputValue('');

            // Clear the Image state
            setSendImg(null);

        } catch (error) {
            console.error("Error saving message:", error);
        }
    };

    const handelInput = (event) => {
        setInputValue(event.target.value);
    }


    const sendImageChat = (event) => {
        setSendImg(event.target.files[0]);
    }

    const handelFileTake = () => {
        inputFile.current.click();
    }

    useEffect(() => {
        if (localStorage.getItem("x-studio-token") === null || localStorage.getItem("x-studio-token") === "") {
            toast.error("Please Login First");
            localStorage.clear();
            navigate("/");
        }
    }, []);

    const clearAllData = async () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");

        setOpen(true);
        try {
            const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            console.error(error);
            setOpen(false);
        }
    }

    if (chats === null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic ? userData.profilePic : blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected} >
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>

                </div>
            </>
        );
    }

    else {

        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={viewImage}
                >
                    <div className={styles.view_chat_image}>
                        <CloseIcon className={styles.crossCancel} onClick={e => setViewImage(null)} />
                        <img src={viewImage} alt="View Image" />
                    </div>
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic ? userData.profilePic : blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected} >
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    {
                        chats.length === 0 && <div style={{ width: "100%", color: "grey", fontSize: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span>No Chat found!</span>
                        </div>
                    }
                    {
                        chats.length > 0 && <div className={styles.main_white_left} style={{ visibility: (selectedChatId && messages) ? "visible" : "hidden" }}>

                            <div className={styles.title_section} >
                                <img src={receiverProfileImage} alt="profile-pic" />
                            <span>{chatName}</span>
                            </div>
                            <div className={styles.chat_section}>

                                <div style={{ width: "100%", height: "88%", overflow: "auto", display: "flex", flexDirection: "column", gap: "3vh" }}>

                                    {messages.map((msg) =>
                                        msg.sender !== loggedUser.fname ? <div className={styles.chat1}>
                                            <img src={receiverProfileImage} alt="receiverProfile" />
                                            <div className={styles.yellow_color_msg}>
                                                {
                                                    msg.type === "text" ? <span>{msg.message}</span> : <img onClick={() => setViewImage(msg.message)} className={styles.chatImage} src={msg.message} alt="Photo" />
                                                }

                                            </div>
                                        </div> : <div className={styles.chat2}>

                                            <div className={styles.yellow_color_msg}>
                                                {
                                                    msg.type === "text" ? <span>{msg.message}</span> : <img onClick={() => setViewImage(msg.message)} className={styles.chatImage} src={msg.message} alt="image" />
                                                }
                                            </div>
                                            <img src={userProfileImage} alt="SenderProfile" />
                                        </div>
                                    )}
                                    {/* Ref to ensure scrolling happens */}
                                    <div ref={messagesEndRef}></div>

                                </div>

                                <div className={styles.showUploadImage} style={{ display: sendImg === null ? "none" : "flex" }}>
                                    <CloseIcon className={styles.crossCancel} onClick={e => setSendImg(null)} />
                                    <img src={sendImg === null ? "" : URL.createObjectURL(sendImg)} alt="sendingImageUpload" />
                                </div>

                                <div className={styles.chat_input}>
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        saveMessage();
                                    }} style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
                                        <input type="text" value={inputValue} onChange={handelInput} onSubmit={() => saveMessage()} placeholder="Type your message here" />
                                        <img src={attach} alt="attach" onClick={handelFileTake} />
                                        <input type="file" style={{ display: "none" }} ref={inputFile} onChange={e => sendImageChat(e)} />
                                        <span onClick={() => saveMessage()}>Send</span>

                                    </form>

                                </div>
                            </div>
                        </div>
                    }

                    {
                        chats.length > 0 && <div className={styles.main_white_right}>

                            <div className={styles.main_white_right_bottom}>
                                <span style={{ marginBottom: "20px" }}>Chat Users</span>
                                {chats.map((chat, i) =>


                                    <div className={styles.people_list} onClick={() => selectChat(chat.id, chat.profileImage, chat.name)} key={chat.id}>
                                        <img src={chat.profileImage} alt="Profile" />
                                        <span>{chat.name}</span>
                                    </div>
                                )}

                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }
}

export default inboxPage
