import axios from "axios";
import { url } from "../constData";

// Function to get an access token
export const getServerAccessToken = async () => {
    const data = await axios.get(`${url}/api/studio/getServerAccessToken`, {
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
        }
    });
    return data.data.token;
};