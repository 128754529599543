import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../constData'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import styles from './css/helpAndSupportPage.module.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';

import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import filterBtn from "./images/icons/filter-icon.svg"
import downArrow from "./images/icons/down-arrow.svg";
import innovationImage from "./images/innovation.svg";
import blackImage from './images/blackImage.png'
import f3 from './images/f3.jpg'
import f1 from './images/f1.jpg'
import f2 from './images/f2.jpg'
import f4 from './images/f4.jpg'
import f5 from './images/f5.jpg'
import f6 from './images/f6.jpg'
import attach from './images/icons/attach.svg'

function helpAndSupportPage() {

    const [fname, setFname] = useState("");
    const [numberSelected, setNumberSelected] = useState("");
    const [emailSelected, setEmailSelected] = useState("");
    const [messagea, setMessagea] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");

    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const userData = JSON.parse(localStorage.getItem("userData"));

    const fetchData = async (event) => {
        event.preventDefault();
        setOpen(true);
        try {
            const response = await axios.post(`${url}/api/studio/help`, {
                fullName: fname,
                email: emailSelected,
                number: numberSelected,
                message: messagea,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }

            });

            if (response.status === 200) {

                toast.success("Email Sent!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setFname("");
                setEmailSelected("");
                setMessagea("");
                setNumberSelected("");
                setOpen(false);
            }
            else if (response.status === 400) {
                toast.error("Try Again", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                
                setOpen(false);
            }

        } catch (error) {
            if (error.response.status === 400) {
                toast.error(`${error.response.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                toast.error(`${error.response.data.error}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            setOpen(false);
        }
    }



    const clearAllData = async () => {
        setOpen(true);
        try {
		localStorage.clear();
                sessionStorage.clear();
                navigate("/");
		const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            setOpen(false);
        }

    }




    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <div className={styles.main_yellow}>
                <div className={styles.left_side_menu}>
                    <div className={styles.studio_title}>
                        <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                        <span className={styles.studio_name}>Studio</span>
                    </div>
                    <div className={styles.main_menu}>
                        <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected} >
                            <div className={styles.main_menu_content}>
                                <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={chatIcon} alt="icons" /><span> Inbox</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={workIcon} alt="icons" /><span> Jobs</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={users3} alt="icons" /><span> Connections</span>
                            </div>
                        </Link>
                        {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span> Invite</span>
                            </div>
                        </Link> */}
                        <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={profileIcon} alt="icons" /><span> Profile</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={calendarIcon} alt="icons" /><span> Schedule
                                    Interview</span>
                            </div>
                        </Link>
                        <Link to="/manager-page" className={styles.manager_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                            </div>
                        </Link>
                        <Link to='/' className={styles.logout_selected}>
                            <div className={styles.main_menu_content} onClick={clearAllData}>
                                <img src={workIcon} alt="icons" /><span>Logout</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.main_white}>
                <div className={styles.helpSupport}>
                    <h2>Help & Support</h2>
                    <div className={styles.formHelp}>
                        <div className={styles.bblock}>
                            <label>
                                Full Name
                            </label>
                            <input type="text" placeholder='Enter your name' value={fname} onChange={e => setFname(e.target.value)} />
                        </div>
                        <div className={styles.bblock}>
                            <label>
                                Phone No.
                            </label>
                            <input type="text" placeholder='Enter your number' value={numberSelected} onChange={e => setNumberSelected(e.target.value)} />
                        </div>
                        <div className={styles.bblock}>
                            <label>
                                Email ID
                            </label>
                            <input type="text" placeholder='Enter your email id' value={emailSelected} onChange={e => setEmailSelected(e.target.value)} />
                        </div>
                        <div className={styles.bblock}>
                            <label>
                                Message
                            </label>
                            <textarea cols="30" rows="10" placeholder='Type your message here' value={messagea} onChange={e => setMessagea(e.target.value)} />
                        </div>

                    </div>
                    <button onClick={fetchData}>Submit</button>
                </div>
                <div className={styles.contactAdmin}>
                    <h2>
                        Contact Admin
                    </h2>
                    <div className={styles.adminEmail}>
                        <h3>
                            Email ID:
                        </h3>
                        <span>rajadeyraj@gmail.com</span>

                    </div>
                </div>


            </div>
        </>
    )
}

export default helpAndSupportPage
