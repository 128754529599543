import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/interview.module.css';

import axios from 'axios';
import { url } from '../constData'

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';

import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import editIcon from "./images/icons/edit.svg"
import verifyIcon from "./images/icons/verify.svg"
import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import filterBtn from "./images/icons/filter-icon.svg"
import downArrow from "./images/icons/down-arrow.svg";
import uploadIcon from "./images/Vector.svg";
import blackImage from './images/blackImage.png'
import f3 from './images/f3.jpg'
import f1 from './images/f1.jpg'
import f2 from './images/f2.jpg'
import f4 from './images/f4.jpg'
import f5 from './images/f5.jpg'
import f6 from './images/f6.jpg'
import attach from './images/icons/attach.svg'

function interviewPage() {

    const userData = JSON.parse(localStorage.getItem("userData"));
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [interviewData, setInterviewData] = useState(null);
    const navigate = useNavigate();

    const clearAllData = async () => {
        setOpen(true);
        try {
		localStorage.clear();
                sessionStorage.clear();
                navigate("/");
		const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            setOpen(false);
        }

    }

    const fetchData = async () => {
        setOpen(true);
        try {
            const resp = await axios.get(`${url}/api/postJob/getInterviews`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            setInterviewData(resp.data);
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }

    useEffect(() => {
        fetchData();

    }, []);








    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.main_yellow}>
                <div className={styles.left_side_menu}>
                    <div className={styles.studio_title}>
                        <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                        <span className={styles.studio_name}>Studio</span>
                    </div>
                    <div className={styles.main_menu}>
                        <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={chatIcon} alt="icons" /><span> Inbox</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={workIcon} alt="icons" /><span> Jobs</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={users3} alt="icons" /><span> Connections</span>
                            </div>
                        </Link>
                        {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span> Invite</span>
                            </div>
                        </Link> */}
                        <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={profileIcon} alt="icons" /><span> Profile</span>
                            </div>
                        </Link>
                        <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={calendarIcon} alt="icons" /><span> Schedule
                                    Interview</span>
                            </div>
                        </Link>
                        <Link to="/manager-page" className={styles.manager_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                            </div>
                        </Link>
                        <Link to='/' className={styles.logout_selected}>
                            <div className={styles.main_menu_content} onClick={clearAllData}>
                                <img src={workIcon} alt="icons" /><span>Logout</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.main_white}>
                <div className={styles.top_div}>
                    {/* <input type="text" placeholder="Search here..." /> */}
                    <span>All Interviews</span>

                </div>
                <div className={styles.bottom_div}>

                    {interviewData != null ?
                        interviewData.post.map((job) =>
                            job.interview.map((data) =>
                                <div className={styles.interview_detail}>
                                    <img src={data.user.profilePic === "" ? blackImage : data.user.profilePic} alt="face-icon" />
                                    <span className={styles.interviewUserName}>
                                        {data.user.fname}
                                    </span>
                                    <span className={styles.main_text}>
                                        {job.description.length > 200 ? job.description.substring(0, 200) : job.description}...
                                    </span>
                                    <span className={styles.time_date}>{data.time}</span>
                                    <span className={styles.time_date}>{new Date(data.date).toDateString()}</span>
                                </div>
                            )

                        ) :  <div style={{ width: "100%", height: "100%", display: interviewData === null || interviewData.post === null || interviewData.interview === 0 ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "50px", fontWeight: "bold", color: "grey" }}>No Data Available</span>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default interviewPage
