import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../components/firebaseSetup";

export const sendInAppNotification = async (receiverId, message, userProfileImage) => {
    try {
        const currentDatetime = new Date();
        const formattedDatetime = currentDatetime.toISOString().slice(0, 19).replace("T", " ");
        const userDocRef = doc(db, "users", receiverId);
        await updateDoc(userDocRef, {
            notification: arrayUnion(`${message}__${userProfileImage}_${formattedDatetime}`),
        });
    } catch (error) {
        console.error(error);
    }
};