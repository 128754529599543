import React, { useState, useEffect } from "react";
import styles from "./css/loginPage.module.css";
import passwordGif from "./images/stPassword.png";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { url } from "../constData";

function LoginPage() {
  const auth = getAuth();

  const [userValue, setUserValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [token, setToken] = useState("");
  const [managerToken, setManagerToken] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loginas, setLoginas] = useState(false);

  const [errorr, setErrorr] = useState(null);

  async function fetchData() {
    setOpen(true);
    if(userValue === "" || passValue === ""){
      toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      setOpen(false);
      return;
    }
    if(passValue.length < 8){
      toast.error("Password must be atleast 8 characters long", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      setOpen(false);
      return;
    }
    try {
      const response = await axios.post(
        `${url}/api/studio/login`,
        {
          email: userValue,
          password: passValue,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      if (response.status === 400) {
        toast.error("Incorrect Password", {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        setOpen(false);
      }
      setToken(response.data.token);
      localStorage.setItem("x-studio-token", response.data.token);
      localStorage.setItem("userData", JSON.stringify(response.data));
      setOpen(false);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        toast.error(`${error.response.data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      }
      setErrorr(error);
      setOpen(false);
    }
  }

  const handleSubmit = async (event) => {
    setOpen(true);
    event.preventDefault();
    if(userValue === "" || passValue === ""){
      toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      setOpen(false);
      return;
    }
    if(passValue.length < 8){
      toast.error("Password must be atleast 8 characters long", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      setOpen(false);
      return;
    }
    if (loginas === true) {
      setOpen(true);
      try {
        const response = await axios.post(
          `${url}/api/manager/login`,
          {
            email: userValue,
            password: passValue,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
            },
          }
        );

        if (response.status === 400) {
          toast.error("Incorrect Password", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
          });
          setOpen(false);
        } else {
          setOpen(false);
        }
        navigate("/managerpanel");
        setManagerToken(response.data.token);
        localStorage.setItem("x-manager-token", response.data.token);
        localStorage.setItem("ManagerData", JSON.stringify(response.data));
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(`${error.response.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
          });
          setOpen(false);
        }
        }}
        else {
          fetchData()
            .then((result) => {
              signInWithEmailAndPassword(
                auth,
                userValue.trim(),
                `${userValue.trim()}password`
              )
                .then((newResult) => {})
                .catch((errs) => {
                });
            })
            .catch((err) => {
            });
        }
      };


  // for studio 
  useEffect(() => {
    if (sessionStorage.getItem("changedPassword") === "yes") {
      toast.success("Password Changed", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      sessionStorage.clear();
    }
    if (sessionStorage.getItem("created") === "yes") {
      toast.success("Studio Account created!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
    }
    
    if (
      localStorage.getItem("x-studio-token") !== null &&
      localStorage.getItem("x-studio-token") !== ""
    ) {
      navigate("/dashboard");
    } else {
    }
  }, [token]);

  // for manager
  useEffect(() => {
    if (localStorage.getItem("x-manager-token") !== null) {
      navigate("/managerpanel");
    } 
  }, [managerToken]);

  const forgotPasswordPage = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 50 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.white_div}></div>
      <div className={styles.yellow_div}>
        <h1 className={styles.login_text}>LOGIN</h1>
        <div className={styles.username_div}>
          <form className={styles.loginForm} onSubmit={handleSubmit}>
            <label className={styles.loginLabel}>Email</label>
            <br />
            <input
              className={styles.LoginInput}
              type="text"
              name="email"
              value={userValue}
              onChange={(event) => setUserValue(event.target.value)}
              placeholder="Enter your email"
            />
            <br />
            <label className={styles.loginLabel}>Password</label>
            <br />
            <input
              className={styles.LoginInput}
              type="password"
              name="password"
              value={passValue}
              onChange={(event) => setPassValue(event.target.value)}
              placeholder="Enter password"
            />
            <p className={styles.forgotpassword}>
              <p
                style={{ display: "inline-block" }}
                onClick={forgotPasswordPage}
              >
                Forgot Password?
              </p>
              <p style={{ display: "inline-block" }}>
                <input
                  style={{ height: "1rem", width: "1rem" }}
                  type="checkbox"
                  name="loginas"
                  value={loginas}
                  onChange={() => setLoginas(!loginas)}
                />{" "}
                &nbsp;Login as Manager
              </p>
            </p>

            <div className={styles.login_button_area}>
              <button className={styles.login_button} type="submit">
                Log in
              </button>
            </div>
          </form>
          <div className={styles.account_text}>
            <h4>Don't have an account yet?</h4>
            <h4 className={styles.signup}>
              <Link to="/signup">Sign up</Link>
            </h4>
          </div>
        </div>
      </div>
      <div>
        <img
          className={styles.login_gif}
          src={passwordGif}
          alt="password-gif"
        />
      </div>
    </>
  );
}

export default LoginPage;
