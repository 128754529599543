import React, { useState, useEffect } from "react";
import styles from "./css/appliedArtist.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../constData";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import chartIcon from "./images/icons/Chart.svg";
import chatIcon from "./images/icons/Chat.svg";
import workIcon from "./images/icons/Work.svg";
import users3 from "./images/icons/users3.svg";
import addUserIcon from "./images/icons/Add User.svg";
import profileIcon from "./images/icons/Profile.svg";
import calendarIcon from "./images/icons/Calendar.svg";
import notificationBtn from "./images/icons/notification.svg";
import settingBtn from "./images/icons/setting-icon.svg";
import filterBtn from "./images/icons/filter-icon.svg";
import downArrow from "./images/icons/down-arrow.svg";
import innovationImage from "./images/innovation.svg";
import blackImage from "./images/blackImage.png";
import f3 from "./images/f3.jpg";
import f1 from "./images/f1.jpg";
import f2 from "./images/f2.jpg";
import f4 from "./images/f4.jpg";
import f5 from "./images/f5.jpg";
import f6 from "./images/f6.jpg";
import threeDot from "./images/icons/threeDot.svg";
import attach from "./images/icons/attach.svg";
import crossBtn1 from "./images/icons/cross1.svg";
import crossBtn from "./images/icons/cross.svg";
import searchIcon from "./images/icons/search.svg";
import camera1 from "./images/icons/camera1.svg";
import TimePicker from "react-time-picker";
import { fontSize } from "@mui/system";

import { db, messaging } from "./firebaseSetup";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc, // Import updateDoc for Firestore updates
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { storage } from "./firebaseSetup";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { sendPushNotification } from "../utils/sendPushNotification";
import { getServerAccessToken } from "../utils/getServerAccessToken";
import { sendInAppNotification } from "../utils/sendInAppNotification";

function jobAppliedArtist() {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const jobData = JSON.parse(localStorage.getItem("singleJobData"));

  // firestore collection reference
  const userCollectionRef = collection(db, "users");
  const chatCollectionRef = collection(db, "chats");

  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [showAccept, setShowAccept] = useState(true);
  const [showShort, setShowShort] = useState(true);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(null);

  const formattedDate = date ? date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : date;
  const formattedTime = time ? time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : time;


  const [jobUserData, setJobUserData] = useState(null);
  const [showOption, setShowOption] = useState(false);

  const [fixInterview, setFixInterview] = useState(false);

  const [showFinal, setShowFinal] = useState(false);

  const [interviewData, setInterviewData] = useState("");

  const [indexUser, setIndexUser] = useState(0);

  const [serverAccessToken, setServerAccessToken] = useState(null); // server access token for firebase push notifications

  // Function to get an access token
  const getAccessToken = async () => {
    const data = await getServerAccessToken();
    setServerAccessToken(data);
  };

  // const tt = ["10:00", ""]

  const navigate = useNavigate();

  const clearAllData = async () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    setOpen(true);
    try {
      const resp = await axios.post(
        `${url}/api/studio/logout`,
        {
          noData: "aa",
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      if (resp.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        setOpen(false);
        navigate("/");
      } else {
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getAccessToken();
    if (localStorage.getItem("x-studio-token") === null || localStorage.getItem("x-studio-token") === "") {
      toast.error("Please Login First");
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const fetchData = async () => {
    setOpen(true);
    try {
      const response = await axios.get(
        `${url}/api/studio/getOneStudioJobDetail?jobId=${jobData._id}&category=${sessionStorage.getItem("category")}`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      sessionStorage.setItem("jobApplicantsData", JSON.stringify(response.data));

      if (response.data[0].interview.length > 0) {
        response.data[0].interview.forEach((element) => {
          if (element.job === jobData._id) {
            setInterviewData(element);
          }
        });
      }
      if (response.data.length > 0) {
        let user = response.data[0];
        user.accepted.map((uu) => {
          if (uu === jobData._id) {
            setShowAccept(false);
          }
        });
        user.shortlisted.map((uu) => {
          if (uu === jobData._id) {
            setShowShort(false);
          }
        });

        setJobUserData(response.data[0]);
        setArtistList(response.data);
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setOpen(false);
    }
  };
  const fetchDataNew = async (index) => {
    setOpen(true);
    try {
      const response = await axios.get(
        `${url}/api/studio/getOneStudioJobDetail?jobId=${jobData._id}&category=${sessionStorage.getItem("category")}`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      sessionStorage.setItem("jobApplicantsData", JSON.stringify(response.data));
      if (response.data[index].interview.length > 0) {
        response.data[index].interview.forEach((element) => {
          if (element.job === jobData._id) {
            setInterviewData(element);
          }
        });
      }
      if (response.data.length > 0) {
        let user = response.data[0];
        user.accepted.map((uu) => {
          if (uu === jobData._id) {
            setShowAccept(false);
          }
        });
        user.shortlisted.map((uu) => {
          if (uu === jobData._id) {
            setShowShort(false);
          }
        });

        setJobUserData(response.data[index]);
        setArtistList(response.data);
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const meetingSchedule = (newTime) => {
    setShowFinal(true);
    const parsedTime = new Date(`January 1, 1970 ${newTime}:00`);
    if (!isNaN(parsedTime.getTime())) {
      setTime(parsedTime);
    } else {
      console.error('Invalid time format:', newTime);
    }
  };

  const showMoreOption = () => {
    setShowOption(!showOption);
  };

  const getUserData = (data, index) => {
    setShowAccept(true);
    setShowShort(true);

    if (data.interview.length === 0) {
      setInterviewData("");
    }

    data.accepted.map((uu) => {
      if (uu === jobData._id) {
        setShowAccept(false);
        setShowShort(false);
      }
    });
    data.shortlisted.map((uu) => {
      if (uu === jobData._id) {
        setShowShort(false);
      }
    });
    data.interview.forEach((element) => {
      if (element.job === jobData._id) {
        setInterviewData(element);
      } else {
        setInterviewData("");
      }
    });
    setIndexUser(index);
    setJobUserData(data);
  };

  const goBack = () => {
    navigate("/job");
  };

  const schInterview = () => {
    setDate(new Date());
    setTime(null);
    setShowFinal(false);
    setFixInterview(!fixInterview);
  };

  const scheduleInterview = async () => {
    setOpen(true);
    try {
      const resp = await axios.post(
        `${url}/api/postJob/interview`,
        {
          userDate: date,
          userTime: time,
          userId: jobUserData._id,
          jobId: jobData._id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      setOpen(false);

      if (resp.status === 200) {
        await fetchDataNew(indexUser);
        schInterview();
        toast.success("Interview Scheduled", {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });

        // Send In App Notification to the artist
        await sendInAppNotification(jobUserData._id, `${userData.fname.charAt(0).toUpperCase() + userData.fname.slice(1)} Scheduled Interview with you for their ${jobData.jobType} job. Interview is on ${formattedDate ? formattedDate : date} at ${formattedTime ? formattedTime : time}`, userData.profilePic);

        // Send Push Notification to the artist
        await sendPushNotification(`Congratulations!!!`, jobUserData._id, `${userData.fname.charAt(0).toUpperCase() + userData.fname.slice(1)} Scheduled Interview with you for their ${jobData.jobType} Job.`, serverAccessToken, "interview");
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const loadImage = () => {
    setLoad(!load);
  };

  const acceptOrShortlist = async (work) => {
    setOpen(true);
    try {
      const resp = await axios.post(
        `${url}/api/studio/acceptJob`,
        {
          work: work,
          jobPostId: jobData._id,
          userId: jobUserData._id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      if (resp.status === 200) {
        setOpen(false);
        toast.success(`${work}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
        await fetchData();

        // Send In App Notification to the artist
        await sendInAppNotification(jobUserData._id, `${userData.fname} ${work.toLowerCase()} you as ${jobData.jobType.toLowerCase()} for their job.`, userData.profilePic);

        // Send Push Notification to the artist
        await sendPushNotification(work, jobUserData._id, `${userData.fname} ${work.toLowerCase()} you as ${jobData.jobType.toLowerCase()} for their job.`, serverAccessToken, work.toLowerCase());
      } else {
        toast.error(`Try Again`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      }
    } catch (error) {
      setOpen(false);
      toast.error(`Try Again`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
    }
  };

  const jobApplicantsData = JSON.parse(sessionStorage.getItem("jobApplicantsData"));
  const uid = userData._id;
  async function createChat(senderId, receiverId) {
    try {
      setOpen(true);

      const listID = [senderId, receiverId];
      listID.sort();
      let chatID = listID.join('_');
      const chatExist = await getDoc(doc(chatCollectionRef, chatID));
      let chatRoom;

      const firstUser = await getDoc(doc(userCollectionRef, senderId));
      const secondUser = await getDoc(doc(userCollectionRef, receiverId));

      if (!chatExist.exists()) {
        const chatDoc = doc(db, 'chats', chatID);
        const firstUserDoc = doc(db, 'users', senderId);
        const secondUserDoc = doc(db, 'users', receiverId);

        // set chat data in Firestore
        await setDoc(chatDoc, {
          admin: senderId,
          chatName: chatID,
          members: arrayUnion(
            `${senderId}__${firstUser.data().fullName.split(' ').join('_')}`,
            `${receiverId}__${secondUser.data().fullName.split(' ').join('_')}`
          ),
          image: `${senderId}__${firstUser.data().profilePic}___${receiverId}__${secondUser.data().profilePic}`,
          recentMessage: '',
          recentMessageType: 'text',
          recentMessageSender: '',
          recentMessageTime: Date.now().toString(),
        })

        // Update the logged-in user's group list
        await updateDoc(firstUserDoc, {
          allChats: arrayUnion(chatID),
        });

        // Update the second user's group list
        await updateDoc(secondUserDoc, {
          allChats: arrayUnion(chatID),
        });


        // Create a ChatRoom object to use in your application
        chatRoom = {
          id: chatID,
          members: [senderId, receiverId],
          image: `${senderId}_${firstUser.data().image}__${receiverId}_${secondUser.data().image}`,
          admin: firstUser.data(),
          sender: firstUser.data(),
          receiver: secondUser.data(),
        };

      }else {
        alert("Group chat already exists");
      }
      setOpen(false);
      navigate("/inbox");

    } catch (err) {
      console.error(err);
      return [];
    }
  }

  if (loading) {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className={styles.main_yellow}>
          <div className={styles.left_side_menu}>
            <div className={styles.studio_title}>
              <img src={blackImage} alt="profile-pic" />
              <span className={styles.studio_name}>Studio</span>
            </div>
            <div className={styles.main_menu}>
              <Link to="/dashboard" className={styles.dashboard_selected}>
                <div className={styles.main_menu_content}>
                  <img src={chartIcon} alt="icons" />
                  <span> Dashboard</span>
                </div>
              </Link>
              <Link to="/inbox" className={styles.inbox_selected}>
                <div className={styles.main_menu_content}>
                  <img src={chatIcon} alt="icons" />
                  <span> Inbox</span>
                </div>
              </Link>
              <Link to="/job" className={styles.jobs_selected}>
                <div className={styles.main_menu_content}>
                  <img src={workIcon} alt="icons" />
                  <span> Jobs</span>
                </div>
              </Link>
              <Link to="/connection" className={styles.connection_selected}>
                <div className={styles.main_menu_content}>
                  <img src={users3} alt="icons" />
                  <span> Connections</span>
                </div>
              </Link>
              <Link to="/invite" className={styles.invite_selected}>
                <div className={styles.main_menu_content}>
                  <img src={addUserIcon} alt="icons" />
                  <span> Invite</span>
                </div>
              </Link>
              <Link to="/profile" className={styles.profile_selected}>
                <div className={styles.main_menu_content}>
                  <img src={profileIcon} alt="icons" />
                  <span> Profile</span>
                </div>
              </Link>
              <Link to="/interview" className={styles.schedule_selected}>
                <div className={styles.main_menu_content}>
                  <img src={calendarIcon} alt="icons" />
                  <span> Schedule Interview</span>
                </div>
              </Link>
              <Link to="/manager-page" className={styles.manager_selected}>
                <div className={styles.main_menu_content}>
                  <img src={addUserIcon} alt="icons" />
                  <span>Manager Section</span>
                </div>
              </Link>
              <Link to="/" className={styles.logout_selected}>
                <div
                  className={styles.main_menu_content}
                  onClick={clearAllData}
                >
                  <img src={workIcon} alt="icons" />
                  <span>Logout</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.main_white}></div>
      </>
    );
  } else {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}
        >
          <img
            src={crossBtn}
            style={{
              cursor: "pointer",
              position: "absolute",
              width: "30px",
              height: "30px",
              top: "4%",
              left: "2%",
            }}
            onClick={loadImage}
          />
          <img
            className={styles.viewImage}
            src={
              jobUserData.profilePic == "" ? blackImage : jobUserData.profilePic
            }
            alt="profilePic"
          />
        </Backdrop>
        <ToastContainer />
        <div className={styles.main_yellow}>
          <div className={styles.left_side_menu}>
            <div className={styles.studio_title}>
              <img
                src={
                  userData.profilePic === "" ? blackImage : userData.profilePic
                }
                alt="profile-pic"
              />
              <span className={styles.studio_name}>Studio</span>
            </div>
            <div className={styles.main_menu}>
              <Link to="/dashboard" className={styles.dashboard_selected}>
                <div className={styles.main_menu_content}>
                  <img src={chartIcon} alt="icons" />
                  <span> Dashboard</span>
                </div>
              </Link>
              <Link to="/inbox" className={styles.inbox_selected}>
                <div className={styles.main_menu_content}>
                  <img src={chatIcon} alt="icons" />
                  <span> Inbox</span>
                </div>
              </Link>
              <Link to="/job" className={styles.jobs_selected}>
                <div className={styles.main_menu_content}>
                  <img src={workIcon} alt="icons" />
                  <span> Jobs</span>
                </div>
              </Link>
              <Link to="/connection" className={styles.connection_selected}>
                <div className={styles.main_menu_content}>
                  <img src={users3} alt="icons" />
                  <span> Connections</span>
                </div>
              </Link>
              <Link to="/invite" className={styles.invite_selected}>
                <div className={styles.main_menu_content}>
                  <img src={addUserIcon} alt="icons" />
                  <span> Invite</span>
                </div>
              </Link>
              <Link to="/profile" className={styles.profile_selected}>
                <div className={styles.main_menu_content}>
                  <img src={profileIcon} alt="icons" />
                  <span> Profile</span>
                </div>
              </Link>
              <Link to="/interview" className={styles.schedule_selected}>
                <div className={styles.main_menu_content}>
                  <img src={calendarIcon} alt="icons" />
                  <span> Schedule Interview</span>
                </div>
              </Link>
              <Link to="/manager-page" className={styles.manager_selected}>
                <div className={styles.main_menu_content}>
                  <img src={addUserIcon} alt="icons" />
                  <span>Manager Section</span>
                </div>
              </Link>
              <Link to="/" className={styles.logout_selected}>
                <div
                  className={styles.main_menu_content}
                  onClick={clearAllData}
                >
                  <img src={workIcon} alt="icons" />
                  <span>Logout</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.main_white}>
          <section className={styles.left}>
            <h1>Artists</h1>
            <div className={styles.list_div}>
              {artistList.map((artist, index) => (
                <div
                  className={styles.artist_name}
                  onClick={() => getUserData(artist, index)}
                >
                  <img
                    src={
                      artist.profilePic === "" ? blackImage : artist.profilePic
                    }
                    alt="face-image"
                  />
                  <span>{artist.fname}</span>
                </div>
              ))}
            </div>
          </section>

          <section
            className={styles.right}
            style={{ display: fixInterview ? "none" : "block" }}
          >
            <div className={styles.right_top}>
              <img
                style={{ cursor: "pointer" }}
                onClick={goBack}
                src={crossBtn}
                alt="cross"
              />
              <button className={styles.edit_btn}>
                {sessionStorage.getItem("category")}
              </button>
            </div>
            {/* <div className={styles.designation_div}>
                            <h2>Designation</h2>
                            <img onClick={showMoreOption} src={threeDot} id={styles.three_dot} alt="three-dot" />
                        </div>
                        <div style={{ display: showOption ? "flex" : "none" }} className={styles.shortlist_option} id={styles.shortlist_option}>

                            {
                                sessionStorage.getItem("category") === "Shortlisted" ?
                                    <>
                                        <span>Accept</span>
                                        <hr />
                                        <span>Decline</span>
                                    </> : sessionStorage.getItem("category") === "Accepted" ?
                                        <span>Decline</span> : <>
                                            <span>Accept</span>
                                            <hr />
                                            <span>Shortlist</span>
                                        </>
                            }
                            <hr />
                            <span>Report</span>
                            <hr />
                            <span>Share</span>
                            <hr />

                        </div> */}
            <div className={styles.right_studio_details}>
              <img
                src={
                  jobUserData.profilePic == ""
                    ? blackImage
                    : jobUserData.profilePic
                }
                alt="profile-image"
                onClick={loadImage}
              />
              <div className={styles.right_studio_text}>
                <div className={styles.right_studio_text_tt}>
                  <span>{jobUserData.fname}</span>
                  <span>
                    {jobUserData.location === "" ? "" : jobUserData.location}
                  </span>
                </div>
                <div className={styles.buttons_area}>
                  <div
                    className={styles.btn}
                    onClick={() =>
                      createChat(
                        userData._id,
                        jobUserData._id,
                      )
                    }
                  >
                    Chat
                  </div>
                  {/* <div className={styles.btn} onClick={()=>{createChat(userData.fname,jobApplicantsData._id,jobUserData.fname)}}>Chat</div> */}
                </div>
              </div>

              <div className={styles.threeArea}>
                <img
                  className={styles.threeOption}
                  onClick={showMoreOption}
                  src={threeDot}
                  alt="three-dot"
                />
              </div>
              <div
                style={{ display: showOption ? "flex" : "none" }}
                className={styles.shortlist_option}
                id={styles.shortlist_option}
              >
                {sessionStorage.getItem("category") === "Shortlisted" ? (
                  <>
                    <span
                      style={{ display: showAccept ? "inline-flex" : "none" }}
                      onClick={() => acceptOrShortlist("Accepted")}
                    >
                      Accept
                    </span>
                    <hr />
                    <span
                      style={{ display: showShort ? "inline-flex" : "inline-flex" }}
                      onClick={() => acceptOrShortlist("Declined")}>
                      Decline
                    </span>
                  </>
                ) : sessionStorage.getItem("category") === "Accepted" ? (
                  <span onClick={() => acceptOrShortlist("Declined")}>
                    Decline
                  </span>
                ) : (
                  <>
                    <span
                      style={{ display: showAccept ? "inline-flex" : "none" }}
                      onClick={() => acceptOrShortlist("Accepted")}
                    >
                      Accept
                    </span>

                    <span
                      style={{ display: showAccept ? "inline-flex" : "none" }}
                      onClick={() => acceptOrShortlist("Declined")}
                    >
                      Decline
                    </span>

                    <span
                      style={{ display: showShort ? "inline-flex" : "none" }}
                      onClick={() => acceptOrShortlist("Shortlisted")}
                    >
                      Shortlist
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* <div className={styles.media_option}>
                            <div className={styles.media}>
                                <div className={styles.icon_image}>
                                    <img src={camera1} alt="camera-icon" />
                                </div>
                                <span>58 Photos</span>
                            </div>
                            <div className="media">
                                <div className="icon-image">
                                    <img src="/images/icons/record1.svg" alt="record-icon" />
                                </div>
                                <span>75 Videos</span>
                            </div>
                            <div className="media">
                                <div className="icon-image">
                                    <img src="/images/icons/document1.svg" alt="document-icon" />
                                </div>
                                <span>3 Documents</span>
                            </div>
                            <div className="media">
                                <div className="icon-image">
                                    <img src="/images/icons/mic1.svg" alt="mic-icon" />
                                </div>
                                <span>9 Audios</span>
                            </div>
                        </div> */}

            <hr />
            <section className={styles.about_candidate}>
              <h3>About Candidate</h3>
              {/* <div className={styles.desc_details}>
                                <span>Location: {jobUserData.location === "" ? "" : jobUserData.location}</span>
                            </div> */}
              <div className={styles.job_desc_desc}>
                <span>Category: {jobUserData.category}</span>
                <span>Gender: {jobUserData.gender}</span>
                <span>Age: {jobUserData.age}</span>
                <span>Height: {jobUserData.height}</span>
                <span>Body Type: {jobUserData.bodyType}</span>
                <span>Hair Color: {jobUserData.hairColor}</span>
                <span>Skills: {jobUserData.skills}</span>
                <span>Bio: {jobUserData.bio}</span>
              </div>
            </section>
            <section className={styles.time_box}>
              <button
                onClick={schInterview}
                style={{
                  display:
                    interviewData.job === jobData._id ? "none" : "inline",
                }}
              >
                Schedule Interview
              </button>

              <span
                style={{
                  display:
                    interviewData.job === jobData._id ? "inline" : "none",
                }}
              >
                Interview: {new Date(interviewData.date).toDateString()} at{" "}
                {new Date(interviewData.time).toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true })}
              </span>
              {/* <div className={styles.schedule}>
                                <span>
                                    Schedule Interview
                                </span>
                                <div className={styles.sh_button}>
                                    <button className={styles.sh_btn}>Later</button>
                                    <button className={styles.sh_btn}>Now</button>
                                </div>
                            </div> */}
            </section>
          </section>

          <section
            className={styles.right}
            style={{ display: fixInterview ? "block" : "none" }}
          >
            <div className={styles.rightTopHeading}>
              <img
                style={{ cursor: "pointer" }}
                onClick={schInterview}
                src={crossBtn}
                alt="cross"
              />
              <h1>Schedule Interview</h1>
            </div>

            <div className={styles.calenderArea}>
              <Calendar
                className={styles.cc}
                onChange={setDate}
                minDate={new Date()}
                value={date}
              />
            </div>

            <div className={styles.timeArea}>
              <span>Set Time</span>
              <TimePicker
                format="HH:mm"
                className={styles.ttpick}
                disableClock={true}
                clearIcon={null}
                onChange={meetingSchedule}
                value={time}
              />
            </div>

            <div
              className={styles.finalScheduled}
              style={{ display: showFinal ? "flex" : "none" }}
            >
              <span>Meeting Scheduled:</span>
              <span>{`${date.toDateString()} at ${time}`}</span>
            </div>
            <div
              className={styles.meetingButton}
              style={{ display: showFinal ? "flex" : "none" }}
            >
              <button onClick={scheduleInterview}>Schedule</button>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default jobAppliedArtist;
