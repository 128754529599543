import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./css/jobDescriptionPage.module.css";

import axios from "axios";
import { url } from "../constData";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";

import chartIcon from "./images/icons/Chart.svg";
import chatIcon from "./images/icons/Chat.svg";
import workIcon from "./images/icons/Work.svg";
import users3 from "./images/icons/users3.svg";
import addUserIcon from "./images/icons/Add User.svg";
import profileIcon from "./images/icons/Profile.svg";
import calendarIcon from "./images/icons/Calendar.svg";
import notificationBtn from "./images/icons/notification.svg";
import crossBtn1 from "./images/icons/cross1.svg";
import crossBtn from "./images/icons/cross.svg";
import searchIcon from "./images/icons/search.svg";
import threeDot from "./images/icons/threeDot.svg";
import settingBtn from "./images/icons/setting-icon.svg";
import filterBtn from "./images/icons/filter-icon.svg";
import downArrow from "./images/icons/down-arrow.svg";
import f3 from "./images/f3.jpg";
import f1 from "./images/f1.jpg";
import f2 from "./images/f2.jpg";
import f4 from "./images/f4.jpg";
import f5 from "./images/f5.jpg";
import f6 from "./images/f6.jpg";
import attach from "./images/icons/attach.svg";
import blackImage from "./images/blackImage.png";
import uploadIcon from "./images/Vector.svg";
import { storage } from './firebaseSetup';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

function jobDescPage() {
  const allJobData = JSON.parse(localStorage.getItem("allJobData"));
  const jobD = JSON.parse(localStorage.getItem("singleJobData"));
  const search = "";
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [jobData, setjobData] = useState(jobD);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image1Preview, setImage1Preview] = useState(jobData.images[0]);
  const [image2Preview, setImage2Preview] = useState(jobD.images[1]);
  let imgurl1 =jobData.images[0];
  let imgurl2 =jobData.images[1];
  let images = []
  if(jobData.images.length>0 ){
    if(jobData.images[0] && jobData.images[1]){
      images.push(jobData.images[0]);
      images.push(jobData.images[1]);
    }
    else if(jobData.images[0]){
      images.push(jobData.images[0]);
    }
    else if(jobData.images[1]){
      images.push(jobData.images[1]);
    }
  }
  const [dateOfPosting, setDateOfPosting] = useState();
  const [dateOfAudition, setDateOfAudition] = useState();
  const [lastDateOfApply, setLastDateOfApply] = useState();
  

  const inputFile = useRef(null);
  const inputFile1 = useRef(null);

  const handelClick = () => {
    inputFile.current.click();
  };
  const handelClick1 = () => {
    inputFile1.current.click();
  };

  useEffect(() => {
    if(jobData.auditionDate){
    const year = new Date(jobData.auditionDate)
      .toISOString()
      .split("T")[0]
      .split("-")[0];
    const month = new Date(jobData.auditionDate)
      .toISOString()
      .split("T")[0]
      .split("-")[1];
    const day = new Date(jobData.auditionDate)
      .toISOString()
      .split("T")[0]
      .split("-")[2];
    const date = `${day}-${month}-${year}`;
    localStorage.setItem("date", date);
    setDateOfAudition(date);
  }
    const Createdyear = new Date(jobData.createdAt)
      .toISOString()
      .split("T")[0]
      .split("-")[0];
    const Createdmonth = new Date(jobData.createdAt)
      .toISOString()
      .split("T")[0]
      .split("-")[1];
    const Createdday = new Date(jobData.createdAt)
      .toISOString()
      .split("T")[0]
      .split("-")[2];
    const postedDate = `${Createdday}-${Createdmonth}-${Createdyear}`;
    localStorage.setItem("postedDate", postedDate);
    setDateOfPosting(postedDate);
    
    if(jobData.lastDate){

    const LastDyear = new Date(jobData.lastDate)
      .toISOString()
      .split("T")[0]
      .split("-")[0];
    const LastDmonth = new Date(jobData.lastDate)
      .toISOString()
      .split("T")[0]
      .split("-")[1];
    const LastDday = new Date(jobData.lastDate)
      .toISOString()
      .split("T")[0]
      .split("-")[2];
    const lastDate = `${LastDday}-${LastDmonth}-${LastDyear}`;
    setLastDateOfApply(lastDate);
    }

    // if(jobData.images.length>0){
    //   if(jobData.images[0] && jobData.images[1]){
    //     setImage1Preview(jobData.images[0]);
    //     setImage2Preview(jobData.images[1]);
    //   }
    //   else if(jobData.images[0]){
    //     setImage1Preview(jobData.images[0]);
    //   }
    //   else if(jobData.images[1]){
    //     setImage2Preview(jobData.images[1]);
    //   }
    // }
  }, []);

  const [showOption, setShowOption] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const [loc, setLoc] = useState(jobData.location);
  const [Auditiondate, setDate] = useState(jobData.auditionDate);
  const [lastDate, setLastDate] = useState(jobData.lastDate);
  const [jobDesc, setjobDesc] = useState(jobData.description);
  const [productionDetails, setProductionDetails] = useState(jobData.productionDetail);
  const [socialMedia, setSocialMedia] = useState(jobData.socialMedia);
  const [keyDetails, setKeyDetails] = useState(jobData.keyDetails);
  const [contactNumber, setContactNumber] = useState(jobData.contactNumber);
  const [studioName, setStudioName] = useState(jobData.studioName);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [jobType, setJobType] = useState(jobData.jobType);

  // const changeJob = (data) => {
  //   setjobData(data);
  //   const year = new Date(data.date).toISOString().split("T")[0].split("-")[0];
  //   const month = new Date(data.date).toISOString().split("T")[0].split("-")[1];
  //   const day = new Date(data.date).toISOString().split("T")[0].split("-")[2];
  //   const date = `${day}-${month}-${year}`;
  //   localStorage.setItem("date", date);

  //   const Createdyear = new Date(data.createdAt)
  //     .toISOString()
  //     .split("T")[0]
  //     .split("-")[0];
  //   const Createdmonth = new Date(data.createdAt)
  //     .toISOString()
  //     .split("T")[0]
  //     .split("-")[1];
  //   const Createdday = new Date(data.createdAt)
  //     .toISOString()
  //     .split("T")[0]
  //     .split("-")[2];
  //   const postedDate = `${Createdday}-${Createdmonth}-${Createdyear}`;
  //   localStorage.setItem("postedDate", postedDate);
  // };

  const handelEdit = () => {
    setEditOption(!editOption);
  };

  const handelBack = () => {
    navigate("/job");
  };

  const handleDelete = async () => {
    setOpen(true);
    try {
      const resp = await axios.post(
        `${url}/api/studio/deleteJob`,
        {
          jobId: jobData._id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      const response = await axios.get(
        `${url}/api/getStudioJob?search=${search}`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      // localStorage.setItem("x-studio-token", response.data.token);
      localStorage.setItem("allJobData", JSON.stringify(response.data));
      setOpen(false);
      navigate("/job");
    } catch (error) {
      alert(error.response.data.message || error);
      setOpen(false);
    }
  };

  const updateDetails = async () => {

    setOpen(true);
    try {

      if (image1) {
        const storageRef = ref(storage, `images/${image1.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image1);
        await new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    imgurl1 = downloadURL;    
                    images[0] = imgurl1;
                    resolve();          
                  });
                }
              );
        })
      }
      if (image2) {
        const storageRef = ref(storage, `images/${image2.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image2);
        await new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                },
                 () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                      imgurl2 = downloadURL;
                      images[1] = imgurl2;
                      resolve();
                  });
                }
              );
        })
      }

      const resp = await axios.post(
        `${url}/api/studio/editJobDetails`,
        {
          location: loc,
          description: jobDesc,
          productionDetail: productionDetails,
          jobId: jobData._id,
          date: Auditiondate, 
          lastDate: lastDate,
          images: images.length === 2 ? [images[0],images[1]]:images.length === 1 && images[0] != null? [images[0]]:images.length === 1 && images[1] != null? [images[1]]:[],
          status: jobData.status,
          socialMedia: socialMedia,
          keyDetails:keyDetails,
          contactNumber:contactNumber,
          studioName: studioName,
          jobType:jobType,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      const response = await axios.get(
        `${url}/api/getStudioJob?search=${search}`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      // localStorage.setItem("x-studio-token", response.data.token);
      localStorage.setItem("allJobData", JSON.stringify(response.data));
      setjobData(resp.data);
      const year = new Date(resp.data.auditionDate)
        .toISOString()
        .split("T")[0]
        .split("-")[0];
      const month = new Date(resp.data.auditionDate)
        .toISOString()
        .split("T")[0]
        .split("-")[1];
      const day = new Date(resp.data.auditionDate)
        .toISOString()
        .split("T")[0]
        .split("-")[2];
      const date = `${day}-${month}-${year}`;
      localStorage.setItem("date", date);
        setDateOfAudition(date);

      const Createdyear = new Date(resp.data.createdAt)
        .toISOString()
        .split("T")[0]
        .split("-")[0];
      const Createdmonth = new Date(resp.data.createdAt)
        .toISOString()
        .split("T")[0]
        .split("-")[1];
      const Createdday = new Date(resp.data.createdAt)
        .toISOString()
        .split("T")[0]
        .split("-")[2];
      const postedDate = `${Createdday}-${Createdmonth}-${Createdyear}`;
      localStorage.setItem("postedDate", postedDate);
        setDateOfPosting(postedDate);

      if(resp.data.lastDate){
      const LastDyear = new Date(resp.data.lastDate)
        .toISOString()
        .split("T")[0]
        .split("-")[0];
      const LastDmonth = new Date(resp.data.lastDate)
        .toISOString()
        .split("T")[0]
        .split("-")[1];
      const LastDday = new Date(resp.data.lastDate)
        .toISOString()
        .split("T")[0]
        .split("-")[2];
      const lastDate = `${LastDday}-${LastDmonth}-${LastDyear}`;
      setLastDateOfApply(lastDate);
      }

      setEditOption(false);
      setOpen(false);
      setImage1Preview(imgurl1);
      setImage2Preview(imgurl2);
      setImage1(null);
      setImage2(null);

    } catch (error) {
      alert(error.response.data.message || error);
      setOpen(false);
    }
  };


  const userData = JSON.parse(localStorage.getItem("userData"));
  const clearAllData = async () => {
    setOpen(true);
    try {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      const resp = await axios.post(
        `${url}/api/studio/logout`,
        {
          noData: "aa",
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      if (resp.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        setOpen(false);
        navigate("/");
      } else {
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <div className={styles.main_yellow}>
        <div className={styles.left_side_menu}>
          <div className={styles.studio_title}>
            <img
              src={
                userData
                  ? userData.profilePic
                    ? userData.profilePic
                    : blackImage
                  : userData.profilePic
              }
              alt="profile-pic"
            />
            <span className={styles.studio_name}>Studio</span>
          </div>
          <div className={styles.main_menu}>
            <Link
              style={{ textDecoration: "none" }}
              to="/dashboard"
              className={styles.dashboard_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={chartIcon} alt="icons" />
                <span> Dashboard</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/inbox"
              className={styles.inbox_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={chatIcon} alt="icons" />
                <span> Inbox</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/job"
              className={styles.jobs_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={workIcon} alt="icons" />
                <span> Jobs</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/connection"
              className={styles.connection_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={users3} alt="icons" />
                <span> Connections</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/invite"
              className={styles.invite_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={addUserIcon} alt="icons" />
                <span> Invite</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/profile"
              className={styles.profile_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={profileIcon} alt="icons" />
                <span> Profile</span>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/interview"
              className={styles.schedule_selected}
            >
              <div className={styles.main_menu_content}>
                <img src={calendarIcon} alt="icons" />
                <span> Schedule Interview</span>
              </div>
            </Link>
            <Link to="/manager-page" className={styles.manager_selected}>
              <div className={styles.main_menu_content}>
                <img src={addUserIcon} alt="icons" />
                <span>Manager Section</span>
              </div>
            </Link>
            <Link to="/" className={styles.logout_selected}>
              <div className={styles.main_menu_content} onClick={clearAllData}>
                <img src={workIcon} alt="icons" />
                <span>Logout</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.main_white}>
        <div className={styles.left}>
          <section className={styles.left_first}>
            <div className={styles.left_top}>
              <img
                onClick={handelBack}
                style={{ cursor: "pointer" }}
                src={crossBtn}
                alt="cross"
              />
              {editOption ? (
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button className={styles.edit_btn} onClick={handelEdit}>
                    Cancel
                  </button>
                  <button className={styles.edit_btn} onClick={updateDetails}>
                    Update
                  </button>
                </div>
              ) : (<div style={{
                  height: "100%",
                  display: "flex",
                  gap:'2vmax'
              }}>
                <button className={styles.edit_btn} onClick={handleDelete}>
                  delete
                </button>
                <button className={styles.edit_btn} onClick={handelEdit}>
                  Edit
                </button>
                </div>
              )}
            </div>
            {/* <div className={styles.designation_div}>
                            <h2>Designation</h2>

                        </div> */}

            <div className={styles.left_studio_details}>
              {/* <img src={jobData.images[0]} alt="profile-image" /> */}
              <div className={styles.left_studio_text}>
                <span>{editOption ?<>Studio Name:{" "}
                <input
                  type="text"
                  style={{
                    width: "96%",
                    height: "100%",
                    padding: "0 2%",
                    margin: 0,
                    fontSize: "1rem",
                    border: "1px solid black",
                  }}
                  value={studioName}
                  onChange={(e) => setStudioName(e.target.value)}
                /></> : jobData.studioName}</span>
                <span>
                  {jobData.location.length == 0 ? "" : jobData.location}
                </span>
              </div>
            </div>
            <div className={styles.posted_details}>
              <span>Posted on {dateOfPosting}</span>
              <span>{jobData.applicants.length} applicants</span>
              <span style={{display:'flex',flexDirection:'column',justifyContent:'space-evenly',widows:'10vmax',alignItems:'end'}}>
              
                {editOption ? (
                
                  <span style={{width:'100%'}}>
                  Audition on:
                  <input
                    type="date"
                    style={{
                      width: "100%",
                      height: "50%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    min={new Date().toISOString().split("T")[0]}
                    value={Auditiondate}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  </span>
                  
                  
                ) : dateOfAudition == "Invalid Date" || dateOfAudition === undefined || dateOfAudition === null
                 ? null : (
                  <span>Audition on: {dateOfAudition}</span>)
                }
              
              
                {editOption ? (
                  <>
                  <span style={{width:'100%',position:'relative'}}>
                  Last Date to apply:
                  <input
                    type="date"
                    style={{
                      width: "100%",
                      height: "50%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    min={new Date().toISOString().split("T")[0]}
                    value={lastDate}
                    onChange={(e) => setLastDate(e.target.value)}
                  />
                  </span>
                  </>
                ) : lastDateOfApply == "Invalid Date" || lastDateOfApply == undefined || lastDateOfApply == null
                ? null : (
                  <span>Last Date to apply: {lastDateOfApply}</span>
                )}
              </span>
            </div>
            <div className={styles.job_main_points}>
              {/* <ul>
                                <li><span>Job related main points</span></li>
                                <li><span>Job related main points</span></li>
                                <li><span>Job related main points</span></li>
                            </ul> */}
            </div>
          </section>
          {jobData.images.length>0 && editOption == false  ?
          <div className={styles.imageSliderArea}>
            {editOption ? (
              <div className={styles.imageSliderAreaEdit}>
                <div className={styles.banner_upload}>
                  <span>Banner/Photos(1)</span>
                  <div
                    className={styles.upload_button}
                    onClick={handelClick}
                    id={styles.upload}
                  >
                    <input
                      onChange={(e) => {
                        setImage1(e.target.files[0]);
                        if (e.target.files[0]) {
                          setImage1Preview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }
                      }}
                      type="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      id={styles.files}
                      accept="image/png, image/jpeg"
                    />
                    {image1Preview ? (
                      <>
                        <img
                          style={{ height: "50%", width: "50%" }}
                          src={image1Preview}
                          alt="picture upload"
                        />
                        <span>Click on Image to Change</span>
                      </>
                    ) : (
                      <>
                        <img src={uploadIcon} alt="picture upload" />
                        <span>Upload banner/photo</span>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.banner_upload}>
                  <span>Banner/Photos(2)</span>
                  <div
                    className={styles.upload_button}
                    onClick={handelClick1}
                    id={styles.upload}
                  >
                    <input
                      onChange={(e) => {
                        setImage2(e.target.files[0]);
                        if (e.target.files[0]) {
                          setImage2Preview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }
                      }}
                      type="file"
                      ref={inputFile1}
                      style={{ display: "none" }}
                      id={styles.files1}
                      accept="image/png, image/jpeg"
                    />
                    {image2Preview ? (
                      <>
                        <img
                          style={{ height: "50%", width: "50%" }}
                          src={image2Preview}
                          alt="picture upload"
                        />
                        <span>Click on Image to Change</span>
                      </>
                    ) : (
                      <>
                        <img src={uploadIcon} alt="picture upload" />
                        <span>Upload banner/photo</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : 
              <>
                <img src={jobData.images[currentImageIndex]} alt="Slider" />
                <ChevronLeftIcon
                  className={styles.prevBtn}
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex - 1 + jobData.images.length) %
                        jobData.images.length
                    )
                  }
                >
                  prev
                </ChevronLeftIcon>
                <ChevronRightIcon
                  className={styles.nextBtn}
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex + 1) % jobData.images.length
                    )
                  }
                >
                  next
                </ChevronRightIcon>
              </>


            }
          </div>:editOption == true  ?
          <div className={styles.imageSliderArea}>
            {editOption ? (
              <div className={styles.imageSliderAreaEdit}>
                <div className={styles.banner_upload}>
                  <span>Banner/Photos(1)</span>
                  <div
                    className={styles.upload_button}
                    onClick={handelClick}
                    id={styles.upload}
                  >
                    <input
                      onChange={(e) => {
                        setImage1(e.target.files[0]);
                        if (e.target.files[0]) {
                          setImage1Preview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }
                      }}
                      type="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      id={styles.files}
                      accept="image/png, image/jpeg"
                    />
                    {image1Preview ? (
                      <>
                        <img
                          style={{ height: "50%", width: "50%" }}
                          src={image1Preview}
                          alt="picture upload"
                        />
                        <span>Click on Image to Change</span>
                      </>
                    ) : (
                      <>
                        <img src={uploadIcon} alt="picture upload" />
                        <span>Upload banner/photo</span>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.banner_upload}>
                  <span>Banner/Photos(2)</span>
                  <div
                    className={styles.upload_button}
                    onClick={handelClick1}
                    id={styles.upload}
                  >
                    <input
                      onChange={(e) => {
                        setImage2(e.target.files[0]);
                        if (e.target.files[0]) {
                          setImage2Preview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }
                      }}
                      type="file"
                      ref={inputFile1}
                      style={{ display: "none" }}
                      id={styles.files1}
                      accept="image/png, image/jpeg"
                    />
                    {image2Preview ? (
                      <>
                        <img
                          style={{ height: "50%", width: "50%" }}
                          src={image2Preview}
                          alt="picture upload"
                        />
                        <span>Click on Image to Change</span>
                      </>
                    ) : (
                      <>
                        <img src={uploadIcon} alt="picture upload" />
                        <span>Upload banner/photo</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : 
              <>
                <img src={jobData.images[currentImageIndex]} alt="Slider" />
                <ChevronLeftIcon
                  className={styles.prevBtn}
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex - 1 + jobData.images.length) %
                        jobData.images.length
                    )
                  }
                >
                  prev
                </ChevronLeftIcon>
                <ChevronRightIcon
                  className={styles.nextBtn}
                  onClick={() =>
                    setCurrentImageIndex(
                      (currentImageIndex + 1) % jobData.images.length
                    )
                  }
                >
                  next
                </ChevronRightIcon>
              </>


            }
          </div>:null}
          <hr />
          <section className={styles.left_second}>
            <h3>Job Description</h3>
            <div className={styles.desc_details}>
            
              <span>Job Type: 
              {editOption ? (
                  <select
                    style={{
                      height: "100%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                  >
                    <option value="Actor">Actor</option>
                                <option value="Model">Model</option>
                                <option value="Singer">Singer</option>
                                <option value="Musician">Musician</option>
                                <option value="Writer">Writer</option>
                                <option value="Dancer">Dancer</option>
                                <option value="Choreographer">Choreographer</option>
                                <option value="Designer">Designer</option>
                                <option value="Other">Other</option>
                  </select>
                ) : (
                  jobData.jobType
                )}
               </span>
              {/* <span>Role: {jobData.jobType}</span> */}
              {/* <span>Required Skills: Skills</span> */}
              <span>
                Location:{" "}
                {editOption ? (
                  <input
                    type="text"
                    style={{
                      width: "96%",
                      height: "100%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={loc}
                    onChange={(e) => setLoc(e.target.value)}
                  />
                ) : (
                  jobData.location
                )}
              </span>
              <span>Contact No:{" "}
                {editOption ? (
                  <input
                    type="text"
                    style={{
                      width: "96%",
                      height: "100%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                ) : (
                  jobData.contactNumber
                )}
              </span>
              <span>Social Media:{" "}
                {editOption ? (
                  <input
                    type="text"
                    style={{
                      width: "96%",
                      height: "100%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={socialMedia}
                    onChange={(e) => setSocialMedia(e.target.value)}
                  />
                ) : (
                  jobData.socialMedia
                )}
              </span>
              <span>Key Details:{" "}
                {editOption ? (
                  <input
                    type="text"
                    style={{
                      width: "96%",
                      height: "100%",
                      padding: "0 2%",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={keyDetails}
                    onChange={(e) => setKeyDetails(e.target.value)}
                  />
                ) : (
                  jobData.keyDetails
                )}
              </span>
            </div>
            <div className={styles.job_desc_desc}>
              <span>
                {editOption ? (
                  <textarea
                    type="text"
                    style={{
                      width: "100%",
                      height: "10vh",
                      margin: 0,
                      fontSize: "1rem",
                      border: "1px solid black",
                    }}
                    value={jobDesc}
                    onChange={(e) => setjobDesc(e.target.value)}
                  />
                ) : (
                  jobData.description
                )}
              </span>
            </div>
          </section>
          <hr />
          <section className={styles.left_third}>
            <h3>Production Detail</h3>
            <span>
              {editOption ? (
                <textarea
                  type="text"
                  style={{
                    width: "100%",
                    height: "10vh",
                    margin: 0,
                    fontSize: "1rem",
                    border: "1px solid black",
                  }}
                  value={productionDetails}
                  onChange={(e) => setProductionDetails(e.target.value)}
                />
              ) : (
                jobData.productionDetail
              )}
            </span>
          </section>
        </div>
        {/* <div className={styles.right}>
                    <section className={styles.right_first}>
                        <div className={styles.search_div}>
                            <img src={searchIcon} alt="search-icon" />
                            <input type="text" placeholder="Search here..." />
                            <img src={crossBtn1} alt="cross-icon" />
                        </div>
                    </section>

                    <section className={styles.right_third}>

                        {
                            allJobData.map((data) =>
                                <div className={styles.box} onClick={() => changeJob(data)}>
                                    <div className={styles.box_top}>
                                        <span>{data.jobType}</span>
                                        <img src={data.images[0]} alt="profile-icon" />
                                    </div>
                                    <p>{data.description.length > 180 ? data.description.substring(0, 180) : data.description}
                                    </p>

                                    <Link style={{ textDecoration: "none" }} to="/job-description"><button className={styles.promote_btn} onClick={data.promoted ? (event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    } : (event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        sendPromoRequest(data._id);
                                    }} >{data.promoted ? "Promoted" : "Promote"}</button></Link>
                                </div>
                            )
                        }

                    </section>
                </div> */}
      </div>
    </>
  );
}

export default jobDescPage;
