import { doc, getDoc } from "firebase/firestore";
import { db } from "../components/firebaseSetup";
import axios from "axios";

export const sendPushNotification = async (title, receiverId, message, serverAccessToken, type) => {
        try {
            const receiverDocRef = doc(db, "users", receiverId);
            const receiverFCMToken = await getDoc(receiverDocRef);
            let data = {
                message: {
                    token: receiverFCMToken.data().fcmToken,
                    notification: {
                        title: title,
                        body: message,
                    },
                    data: {
                        type: type,
                    }
                }
            }

            // Axios configuration
            const config = {
                method: "post",
                url: 'https://fcm.googleapis.com/v1/projects/yourfriday-4f51b/messages:send',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${serverAccessToken}`, // Include the Bearer token
                },
                data: data, // The payload to send
            };

            await axios(config);
        } catch (error) {
        }
    };