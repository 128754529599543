import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/forgotpassword.module.css';
import passwordGif from './images/stPassword.png'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, where, query, setDoc } from 'firebase/firestore';

import { auth, db } from './firebaseSetup';


import { url } from '../constData';

function forgotPassword() {

    const [fname, setFname] = useState("");
    const [numberSelected, setNumberSelected] = useState("");
    const [emailSelected, setEmailSelected] = useState("");
    const [showPass, setShowPass] = useState("");
    const [password, setPassword] = useState("");
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState("");

    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const userCollectionRef = collection(db, "users");


    const fetchData = async (event) => {
        event.preventDefault();
        setOpen(true);
        try {
            const response = await axios.post(`${url}/api/send/otp/forget/password`, {

                number: numberSelected,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }

            });

            if (response.status === 200) {

                toast.success("Otp Sent!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setShowOTP(true);
                setOpen(false);
            }
            else if (response.status === 400) {
                toast.error("Try Again", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                setOpen(false);
            }

        } catch (error) {
            if (error.response.status === 400) {
                toast.error(`${error.response.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                toast.error(`${error.response.data.error}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            setOpen(false);
        }
    }

    const sentOTP = async (event) => {
        setOpen(true);
        event.preventDefault();
        if(otp === "" || password === ""){
            toast.error("Please enter otp and password", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
            setOpen(false);
            return;
        }
        if(password.length < 8){
            toast.error("Password must be atleast 8 characters long", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
            setOpen(false);
            return;
        }
        try {
            const resp = await axios.post(`${url}/api/studio/verifyOTP`, {
                number: numberSelected,
                otp: otp,
                password: password,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }
            });

            if (resp.status === 200) {
                setOpen(false);
                sessionStorage.setItem("changedPassword", "yes");
                navigate("/");

                // toast.success("Password Changed", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'toast-message',
                // });
            }
            else if (resp.status === 400) {
                toast.error(`${resp.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setOpen(false);
            }
            else {
                toast.error("something went wrong");
                setOpen(false);
            }
        } catch (error) {
            if (error.response.status === 400) {
                toast.error(`${error.response.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                toast.error(`${error.response.data.error}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            toast.error("Try Again");
            setOpen(false);
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 0 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <div className={styles.white_div}>
            </div>
            <div className={styles.yellow_div}>
                <h1 className={styles.login_text}>Forgot Password</h1>
                <div className={styles.username_div}>
                    <form id='form1' className={styles.form11} onSubmit={fetchData}>
                        {/* <label>Username</label>
                        <br />
                        <input type="text" placeholder="Enter your name" value={fname} onChange={e => setFname(e.target.value)} />
                        <br />
                        <label>Phone no.</label>
                        <br />
                        <input type="text" placeholder="Enter your phone no." value={numberSelected} onChange={e => setNumberSelected(e.target.value)} />
                        <br /> */}
                        <label className={styles.fpLable}>Mobile Number</label>
                        <input className={styles.fpInput} type="text" placeholder="Enter your mobile number" value={numberSelected} onChange={e => setNumberSelected(e.target.value)} />
                        {/* <p className={styles.infoNote}>*If your email is Registered than you will get a OTP on your registred Mobile Number.</p> */}
                        <br style={{ display: showOTP ? "block" : "none" }} />
                        <label className={styles.fpLable} style={{ display: showOTP ? "block" : "none" }}>OTP</label>
                        <input className={styles.fpInput} style={{ display: showOTP ? "block" : "none" }} type="text" placeholder="Enter OTP" value={otp} onChange={e => setOtp(e.target.value)} />

                        <label className={styles.fpLable} style={{ display: showOTP ? "block" : "none" }}>New Password</label>
                        <input className={styles.fpInput} style={{ display: showOTP ? "block" : "none" }} type="password" placeholder="Enter New Password" value={password} onChange={e => setPassword(e.target.value)} />

                        <button type="submit" form='form1' style={{ display: showOTP ? "none" : "block" }} className={styles.signup_buttonn}>Get OTP</button>
                        <button type="submit" onClick={sentOTP} style={{ display: showOTP ? "block" : "none" }} className={styles.signup_buttonn}>Set Password</button>

                    </form>

                </div>
            </div>
            <div>
                <img className={styles.login_gif} src={passwordGif} alt="password-gif" />
            </div>
        </>
    )
}

export default forgotPassword;
