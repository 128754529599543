import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { url } from '../constData'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/jobsApplied.module.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';


import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import filterBtn from "./images/icons/filter-icon.svg"
import downArrow from "./images/icons/down-arrow.svg";
import innovationImage from "./images/innovation.svg";
import blackImage from './images/blackImage.png'
import f3 from './images/f3.jpg'
import f1 from './images/f1.jpg'
import f2 from './images/f2.jpg'
import f4 from './images/f4.jpg'
import f5 from './images/f5.jpg'
import f6 from './images/f6.jpg'
import attach from './images/icons/attach.svg'
import crossBtn from "./images/icons/cross.svg"

function jobsAppliedPage() {

    const userData = JSON.parse(localStorage.getItem("userData"));
    const search = "";


    const [newUserData, setnewUserData] = useState('')


    const [errorr, setErrorr] = useState(null);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const [filteredList, setFilteredList] = useState(null);

    const [uuid, setUuid] = useState("");


    // paypal work
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);


    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: "Sunflower",
                        amount: {
                            currency_code: "USD",
                            value: 9.69,
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };


    // // check Approval
    // const onApprove = (data, actions) => {
    //     return actions.order.capture().then(function (details) {
    //         const { payer } = details;
    //         setSuccess(true);
    //     });
    // };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
          const { payer } = details;
          setSuccess(true);
          axios
            .post(`${url}/api/admin/addPayment`,
              {
                from: "Studio",
                name: userData.fname,
                type: "Promotion",
                paymentDate: new Date().toLocaleDateString(),
                amount: 9.69,
                status: "Received",
              }
            )
            .then((res) => {
              // Handle success or error
            })
            .catch((error) => {
              console.error("Error adding payment:", error);
            });
        });
      };


    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };


    useEffect(() => {
        if (success) {
            alert("Payment successful!!");
            sendPromoRequest(uuid);
        }
    },
        [success]
    );

    const startPromoteProcess = (uid) => {
        setUuid(uid);
        setShow(true);

    }

    const SearchChange = (event) => {


        setFilteredList(newUserData.filter((item) => {
            return item.description.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        }));

    }



    async function fetchData() {
        setOpen(true);
        try {
            const response = await axios.get(`${url}/api/studio/showWorkingJobs?working=applied&search=${search}`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": userData.token.toString(),
                }
            });
            // localStorage.setItem("x-studio-token", response.data.token);
            localStorage.setItem("allJobData", JSON.stringify(response.data));
            setnewUserData(JSON.parse(localStorage.getItem("allJobData")));
            setOpen(false);


        } catch (error) {
            setErrorr(error);
            setOpen(false);

        }
    }

    useEffect(() => {
        if (localStorage.getItem("x-studio-token") !== null && localStorage.getItem("x-studio-token") !== "") {

            fetchData();


        }
        else {
        }
    }, []);


    const jobDesc = (data) => {
        sessionStorage.setItem("category", "Applied");
        localStorage.setItem("singleJobData", JSON.stringify(data));
        navigate("/applied-artists");
    }


    const sendPromoRequest = async (jobId) => {
        setOpen(true);
        try {
            const resp = await axios.post(`${url}/api/studio/promoteJobReqToAdmin`, {
                jobId: jobId,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": userData.token.toString(),
                }
            });
            if (resp.status === 200) {
                setOpen(false);
                toast.success(`${resp.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });

            }
            else {
                setOpen(false);
                toast.error("Promotion Request Failed!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
        } catch (error) {
            setOpen(false);
            toast.error("Promotion Request Failed", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
        }
    }

    const clearAllData = async () => {
	    	localStorage.clear();
                sessionStorage.clear();
                navigate("/");
        setOpen(true);
        try {
            const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            setOpen(false);
        }

    }


    if (newUserData === "") {
        return (

            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    <div className={styles.top_div}>
                        <div className={styles.top_one_div}>
                            <input type="text" placeholder="Search here..." />
                            {/* <button className={styles.notification_button}><img src={notificationBtn}
                                alt="notification-icon" /></button>
                            <button className={styles.setting_button}><img src={settingBtn} alt="setting-icon" /></button>
                            <button className={styles.filter_button}><img src={filterBtn} alt="filter-icon" /></button> */}
                        </div>
                        <Link style={{ textDecoration: "none" }} to="/post-job"><button className={styles.post_job}>Post Job</button></Link>
                    </div>

                    <div className={styles.main_job_section}>
                        <div className={styles.job_section_title}>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to='/job'><button className={styles.job_option_btn} id={styles.all_jobs}>All
                                Jobs</button></Link></div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-applied"><button className={styles.job_option_btn}
                                id={styles.applied}>Applied</button></Link>
                            </div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-accepted"><button className={styles.job_option_btn}
                                id={styles.accepted}>Accepted</button></Link>
                            </div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-short"><button className={styles.job_option_btn} id={styles.shortlisted}>
                                Shortlisted</button></Link></div>
                        </div>

                        <div className={styles.main_content}>
                            <div className={styles.main_content_top}>
                                <span>Category</span>
                                <img src={downArrow} alt="down-arrow" />
                            </div>
                            <hr />
                            <div className={styles.job_listed}>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
    else {
  
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={show}
                >
                    <img src={crossBtn} style={{ cursor: "pointer", position: "absolute", width: "30px", height: "30px", top: "4%", left: "2%" }} onClick={() => setShow(false)} />
                    <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "1vw",
            maxHeight: "90vh",
            borderRadius: "10px",
          }}
        >
          <div style={{ width: "40vw", maxHeight: "95vh", padding: "4px, 0" }}>
            <PayPalScriptProvider
              options={{
                "client-id":
                  "Ad0bmje1jZ7jEuOosaOb_B4IKfluOldXdmCAAai4y0VKsGCUQosAJghTE76_3DLq6NfRttuDXIDZM8KU",
              }}
            >
              {show ? (
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              ) : null}
            </PayPalScriptProvider>
          </div>
        </div>
                </Backdrop>

                <ToastContainer />
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    <div className={styles.top_div}>
                        <div className={styles.top_one_div}>
                            <input type="text" placeholder="Search here..." onChange={SearchChange} />
                            {/* <button className={styles.notification_button}><img src={notificationBtn}
                                alt="notification-icon" /></button>
                            <button className={styles.setting_button}><img src={settingBtn} alt="setting-icon" /></button>
                            <button className={styles.filter_button}><img src={filterBtn} alt="filter-icon" /></button> */}
                        </div>
                        <Link style={{ textDecoration: "none" }} to="/post-job"><button className={styles.post_job}>Post Job</button></Link>
                    </div>

                    <div className={styles.main_job_section}>
                        <div className={styles.job_section_title}>
                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to='/job'><button className={styles.job_option_btn} id={styles.all_jobs}>All
                                Jobs</button></Link></div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-applied"><button className={styles.job_option_btn}
                                id={styles.applied}>Applied</button></Link>
                            </div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-accepted"><button className={styles.job_option_btn}
                                id={styles.accepted}>Accepted</button></Link>
                            </div>

                            <div className={styles.job_option}><Link style={{ textDecoration: "none" }} to="/job-short"><button className={styles.job_option_btn} id={styles.shortlisted}>
                                Shortlisted</button></Link></div>
                        </div>

                        {newUserData.length === 0 ? <div className={styles.main_image}>
                            <img src={innovationImage} alt="main-image" />
                            <span className={styles.text1}>No one Applied to your Jobs</span>
                            <span>Please wait till anyone apply</span>
                            {/* <Link style={{ textDecoration: "none" }} to="/post-job"><button className={styles.post_job_2}>Post Job</button></Link> */}
                        </div> : <div className={styles.main_content}>
                            <div className={styles.main_content_top}>
                                <span>Category</span>
                                <img src={downArrow} alt="down-arrow" />
                            </div>
                            <hr />
                            <div className={styles.job_listed}>
                                {
                                    filteredList === null ?

                                        newUserData.map((data) =>
                                            <div className={styles.boxes} onClick={() => jobDesc(data)}>
                                                <div className={styles.box_top}>
                                                    <span>{data.jobType}</span>
                                                    {data.images[0]?<img src={data.images[0]} alt="profile-icon" />:null}
                                                </div>
                                                {/* <h3>$14,000-$20,000</h3> */}
                                                <p>{data.description.length > 100 ? data.description.substring(0, 100) : data.description}...
                                                </p>
                                                <Link style={{ textDecoration: "none" }} ><button className={styles.promote_btn} onClick={data.promoted ? (event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                } : (event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    startPromoteProcess(data._id);

                                                }}>{data.promoted ? "Promoted" : "Promote"}</button></Link>
                                                {/* <Link style={{ textDecoration: "none" }} to="/job-description"><button className={styles.promote_btn}>Promote</button></Link> */}
                                            </div>
                                        ) : filteredList.map((data) =>
                                            <div className={styles.boxes} onClick={() => jobDesc(data)}>
                                                <div className={styles.box_top}>
                                                    <span>{data.jobType}</span>
                                                    {data.images[0]?<img src={data.images[0]} alt="profile-icon" />:null}
                                                </div>
                                                {/* <h3>$14,000-$20,000</h3> */}
                                                <p>{data.description.length > 100 ? data.description.substring(0, 100) : data.description}...
                                                </p>
                                                <Link style={{ textDecoration: "none" }} ><button className={styles.promote_btn} onClick={data.promoted ? (event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                } : (event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    startPromoteProcess(data._id);

                                                }}>{data.promoted ? "Promoted" : "Promote"}</button></Link>
                                                {/* <Link style={{ textDecoration: "none" }} to="/job-description"><button className={styles.promote_btn}>Promote</button></Link> */}
                                            </div>
                                        )

                                }

                            </div>
                        </div>}

                    </div>
                </div>

            </>
        )
    }
}

export default jobsAppliedPage
