import React,{useState,useEffect} from "react";
import styles from "./css/managerDashboard.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import {url} from "../constData";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";
import workIcon from './images/icons/Work.svg'



function ManagerDashboard() {
    const [open, setOpen] = useState(false);
    const [linkList, setLinkList] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("ManagerData"));
    const email = userData.email;
    async function fetchData() {
        setOpen(true);
        try {
            const response = await axios.get(
                `${url}/api/manager/getAllData/${email}`,
                {
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "x-manager-token": localStorage.getItem("x-manager-token").toString(),
                    },
                }
            );
            setUserInfo(response.data.fname);
            setLinkList(response.data.url);
            setOpen(false);
        } catch (error) {
            setOpen(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [email]);

    const clearAllData = () => {
        localStorage.clear();
        navigate("/");
    };

  return (<>
    <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <span className={styles.studio_name}>Hi,{userInfo}</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    

                <section className={styles.popularStudios1}>
              <div className={styles.popStud1}>
                <h2>Links List</h2>
                <div className={styles.iconsArea}>
                  {/* <img src={editIcon} alt="edit-icon" />
                                    <img src={deleteIcon} alt="delete-icon" />
                                    <img src={addIcon} alt="addIcon-icon" /> */}
                </div>
              </div>
              <hr />
              <div className={styles.pSTitle1}>
                <span>link</span>
                <span>Date</span>
              </div>
              <div className={styles.scrollArea}>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", fontSize: "40px", fontWeight: "bolder", color: "grey" }}>No Data Available</div> */}
                {linkList.length === 0 ? (
                  <div className={styles.nodatafound}>
                    <span>No Data Found</span>
                  </div>
                ): (
                  linkList.map((item, index) => (
                    <div key={index} className={styles.pSmainDiv}>
                      <div
                        className={styles.pSstudioDetails1}>
                        <span>{item.url}</span>
                        <span>{item.urlDate}</span>
                      </div>
                    </div>
                  ))
                )}
                </div>
            </section>
                            
                            </div>
                
  </>
  );
}

export default ManagerDashboard;


